import React from 'react'
import AllCourseCards from '../Components/AllCourseCards'
import { useSelector } from 'react-redux'

const AllCourses = () => {
    const all_courses = useSelector((state)=>state?.courses?.data)
    window.scroll(0,0)
  return (
    <div className='mt-16'>

        {all_courses && <AllCourseCards data={all_courses}/>}
    </div>
  )
}

export default AllCourses