import React from 'react'


const ErrorPage = () => {

  return (
    <div className='p-32'>
    </div>
  )
}

export default ErrorPage