import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SelectedCourse from "../Components/SelectedCourse";
import AllCourses from "./AllCourses";
import Courses from "./Courses";

const CourseDetails = () => {
  const { courseid } = useParams();
  const all_courses = useSelector((state) => state?.courses?.data);
  const selected = all_courses?.find((res) => res?._id === courseid);
  
  return <div className="mt-16">
    <SelectedCourse data={selected} />
    <Courses/>
    
    </div>;
};

export default CourseDetails;
