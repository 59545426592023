import React from "react";
import { useNavigate } from "react-router-dom";

const AllCourseCards = ({ data }) => {
  const array = [1, 2, 3, 4, 5, 6, 6, 7, 8, 9, 10];
  const navigate = useNavigate();
  return (
    <div className="p-6 grid  grid-cols-1 md:grid-cols-4  gap-6">
      {data?.map((res) => (
        <div class="p-6 bg-stone-100 text-stone-700 border border-gray-200 rounded-lg shadow-lg shadow-stone-500">
          <a href="#">
            <h5 class="mb-2  text-2xl font-bold tracking-tight line-clamp-2 uppercase text-gray-900">
              {res?.name || "name"}
            </h5>
          </a>
          <p class="mb-3 font-normal text-sm  line-clamp-4 text-stone-700">
            {res?.description || "lorem40"}
          </p>
          <a
            onClick={() => navigate(`/course-details/${res?._id}`)}
            class="inline-flex cursor-pointer items-center px-3 py-2 text-sm font-medium text-center text-white bg-stone-700 rounded-lg hover:bg-stone-900 focus:ring-4 focus:outline-none focus:ring-blue-300 "
          >
            Read more
            <svg
              class="rtl:rotate-180 w-3.5 h-3.5 ms-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </a>
        </div>
      ))}
    </div>
  );
};

export default AllCourseCards;
