import React from 'react'
import SignupForm from '../Components/SignupForm';

const SignUp = () => {
    window.scrollTo(0, 0);
  return (
    <div><SignupForm/></div>
  )
}

export default SignUp