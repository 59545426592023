import React from 'react'
import LoginComponent from '../Components/LoginComponent'

const Login = () => {
    window.scrollTo(0, 0);
  return (
    <div><LoginComponent/></div>
  )
}

export default Login