import moment from "moment";
import React from "react";
import LongParagraphComponent from "./LongParagraphComponent";

const SelectedCourse = ({ data }) => {
  return (
    <article className="max-w-2xl px-6 py-24 mx-auto space-y-16 ">
      <div className="w-full mx-auto space-y-4">
        <h1 className="text-5xl capitalize font-bold leadi">
          {data?.name}
        </h1>
        <div className="flex flex-wrap space-x-2 text-sm dark:text-gray-400">
         
        </div>
        <p className="text-sm dark:text-gray-400">
          by
          <a
            href="#"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:underline mx-1 dark:text-violet-400"
          >
            <span>Academic Pathway experts</span>
          </a>
          posted on
            <span className="mx-1">{moment(data?.createdAt).format('lll')}</span>
        </p>
      </div>
      <div className="text-gray-600">
        <p><LongParagraphComponent text={data?.description}/></p>
      </div>
    </article>
  );
};

export default SelectedCourse;
